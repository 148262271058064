<template>
  <div style="text-align: center;">
    Pagina Web Solo disponible para mobiles
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: 'Incorrect Device',
    link: [
      { vmid: 'icon', rel: 'icon', href: '/favicon.ico' }
    ]
  }
};
</script>
