<template>
  <div>
    Esta tienda no se encuentra disponible por el momento en nuestro sitio web
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: 'Page not found',
    link: [
      { vmid: 'icon', rel: 'icon', href: '/favicon.ico' }
    ]
  }
};
</script>
